import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import requestData, { parseResponseWithHeaders } from '@Network/index';
import { toRmiLicense } from '@Reducers/user/models';
import { MIMETypes, SearchHistoryTypes } from '@Constants/common';
import { Feedback } from '@Constants/home';
import { RTS_REQUEST_TIMEOUT } from '@Constants/support';
import { ProductKeys } from '@Constants/product';

dayjs.extend(utc);

export function rtsSupportModalCreateTicket(formData) {
	return requestData('POST', 'RTS_CREATE_TICKET', { formData, timeout: RTS_REQUEST_TIMEOUT }, MIMETypes.MULTIPART)
		.then(res => {
			return res.data;
		})
		.catch(err => {
			throw err;
		});
}

export function getNotifications(language) {
	return requestData('GET', 'NOTIFICATIONS', { data: { language } })
		.then(res => res.data)
		.catch(err => {
			throw err;
		});
}

export function postFeedback(feedback: Feedback) {
	return requestData('POST', 'FEEDBACK', { bodyData: feedback })
		.then(res => res.data)
		.catch(err => {
			throw err;
		});
}

export function getReadNews() {
	return requestData('GET', 'USER_NEWS')
		.then(res => res.data)
		.catch(err => {
			throw err;
		});
}

export function postReadNews(ids: string[]) {
	return requestData('POST', 'USER_NEWS', { bodyData: { readUserNewsIdList: ids } })
		.then(res => res.data)
		.catch(err => {
			throw err;
		});
}

export function getSearchHistory(searchHistoryType: SearchHistoryTypes, parameters) {
	return requestData('GET', 'SEARCH_HISTORY', { data: { searchHistoryType, ...parameters } })
		.then(res => res.searchedTerms)
		.catch(err => {
			throw err;
		});
}

export function postSearchHistoryTerm(searchHistoryType: SearchHistoryTypes, searchTerm, parameters = {}) {
	return requestData('POST', 'SEARCH_HISTORY', {
		data: { searchHistoryType, ...parameters },
		bodyData: { term: searchTerm, time: dayjs.utc().format() },
	})
		.then(res => res.searchedTerms)
		.catch(err => {
			throw err;
		});
}

export function getRmiLicenses(productKey: ProductKeys | string = '') {
	const suffix = productKey ? `${productKey}` : '';

	const parseRmiLicenseData = responseData => {
		const resData = responseData?.data;
		const licenses = Array.isArray(resData?.data) ? resData?.data : [resData?.data].filter(Boolean);
		const parsedLicenses = licenses.map(item => toRmiLicense(item));

		return {
			count: resData?.count || 0,
			data: parsedLicenses,
			responseCode: resData?.responseCode || '',
			succeed: resData?.responseCode || false,
		};
	};

	return requestData('GET', 'GET_RMI_LICENSES', { suffix }, null, true)
		.then(res => {
			return parseResponseWithHeaders(res, parseRmiLicenseData);
		})
		.catch(err => {
			throw err;
		});
}

export const postLuckyDrawParticipation = (contestId: string) => {
	return requestData('POST', 'PARTICIPATE_LUCKY_DRAW', {
		interpolate: { contestId },
	});
};

export const getPpvPackages = () => {
	return requestData('GET', 'GET_PPV_PACKAGES')
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
};

export const getPpvLicenses = (pageIndex = 0, pageSize = 0) => {
	return requestData('GET', 'GET_PPV_LICENSES', { interpolate: { pageIndex, pageSize } })
		.then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		});
};
